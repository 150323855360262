<template>
  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
    <div class="card card-custom gutter-b card-stretch" :class="{'overlay overlay-block': isBlocking}">
      <!--begin::Body-->
      <div class="card-body">
        <div class="d-flex flex-column justify-content-between h-100">

        
        <!--begin::User-->
        <div class="d-flex align-items-start mb-7 flex-fill">
          <!--begin::Pic-->
          <div class="d-flex align-items-center">
            <!--begin::Pic-->
            <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
              <div class="symbol bg-gray-100 symbol-lg-50 p-2">
                <img :src="icon" :alt="title.tr">
              </div>
            </div>
            <!--end::Pic-->
            <!--begin::Title-->
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bold font-size-h6 mb-0 provide-title">{{ title.tr }}</span>
              <span class="text-muted font-weight-bold font-size-md mb-0 provide-muted-title">{{ title.en }}</span>
            </div>
            <!--end::Title-->
          </div>
          <!--end::Title-->
        </div>
        <!--end::User-->
        <div class="d-flex align-items-center">
          <b-button @click="$bvModal.show(`modal-icon-${id}`)" type="button" v-b-tooltip.hover title="İkonu değiştir" class="btn btn-icon btn-lg mr-2 btn-light font-weight-bolder text-uppercase py-4">
            <i class="flaticon2-photograph icon-md"></i>
          </b-button>
          <button @click="openModal" type="button" class="btn flex-fill btn-sm btn-light font-weight-bolder text-uppercase py-4">Düzenle</button>
          <b-button @click="removeModal" type="button" v-b-tooltip.hover title="Sil" class="btn btn-icon btn-lg ml-2 btn-light font-weight-bolder text-uppercase py-4">
            <i class="flaticon2-trash icon-md"></i>
          </b-button>
        </div>
        </div>
      </div>
      <!--end::Body-->
      <div class="overlay-layer bg-dark-o-10" v-if="isBlocking">
        <div class="spinner spinner-primary"></div>
      </div>
    </div>
    
    <b-modal :id="`modal-edit-${id}`" :ref="`modal-edit-${id}`" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleModalCancel" @hide="handleModalCancel" @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ modal.activeStep }} ADIM/{{ modal.steps.length }}</label>
            <div class="d-flex align-items-center">
              <Flag :code="modal.steps[modal.activeStep - 1].country" size="M" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
              <h5 class="ml-2 mb-0">
                {{ modal.steps[modal.activeStep - 1].title }}
              </h5>
            </div>
          </div>
        </div>
      </template>

      <div class="max-h-300px">
        <div v-if="modal.activeStep === 1">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Olanak</label>
            <input type="text" class="form-control form-control-lg" v-model="modal.title.tr">
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Açıklama (İsteğe bağlı)</label>
            <textarea
              class="form-control mb-2"
              rows="3"
              placeholder="Açıklamayı buraya yazın"
              maxlength="150"
              v-model="modal.detail.tr"
            ></textarea>
          </div>
        </div>
        <div v-if="modal.activeStep === 2">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Olanak</label>
            <input type="text" class="form-control form-control-lg" v-model="modal.title.en">
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Açıklama (İsteğe bağlı)</label>
            <textarea
              class="form-control mb-2"
              rows="3"
              placeholder="Açıklamayı buraya yazın"
              maxlength="150"
              v-model="modal.detail.en"
            ></textarea>
          </div>
        </div>
      </div>
      
      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
            v-if="modal.activeStep == 1"
          >İptal et</button>
           <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="modal.activeStep = modal.activeStep - 1" 
            v-if="modal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="modal.title.en.length === 0"
            @click="ok" 
            v-if="modal.activeStep == modal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="modal.title.tr.length === 0"
            @click="modal.activeStep = modal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>

     <b-modal :id="`modal-icon-${id}`" :ref="`modal-icon-${id}`" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleIconModalCancel" @hide="handleIconModalCancel" @ok="handleIconModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ title.tr }}</label>
            <h5>İkonu güncelle</h5>
          </div>
        </div>
      </template>

      <div class="max-h-300px">
        <div class="form-group">
          <file-pond
            name="icon"
            allow-multiple="false" 
            accepted-file-types="image/*"
            max-files="1" 
            maxFileSize="20KB"
            :file-rename-function="renameFile" 
            v-on:removefile="removeFile"
            :server="{ process }"
            label-idle='<div style="width:100%;height:100%;">
              <h3 style="font-size: 14px">
                İkonu Sürükle &amp; Bırak veya <span class="filepond--label-action" tabindex="0">Gözat</span><br>
              </h3>
            </div>'
          />
          <input type="hidden" name="provideIcon" v-model="iconModal.provideIcon">
        </div>
      </div>
      
      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="iconModal.provideIcon === null"
            @click="ok" 
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>

  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from '@/core/services/jwt.service';

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm.js';
import FilePondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm.js';
import 'filepond/dist/filepond.min.css';
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginFileRename);

export default {
  props: {
    id: Number, 
    icon: String,
    title: Object,
    detail: Object
  },
  components: {
    FilePond
  },
  data() {
    return {
      modal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Türkçe',
            country: 'TR'
          },
          {
            number: 2,
            title: 'İngilizce',
            country: 'GB-UKM'
          }
        ],
        id: null,
        title: { tr: '', en: '' },
        detail: { tr: '', en: '' },
      },
      fileUpload: {
        uploadUrl: '',
        isUploading: false
      },
      iconModal: {
        provideIcon: null
      },
      isBlocking: false
    }
  },
  methods: {
    openModal() {
      this.modal.id = this.id;
      this.modal.title = Object.assign({}, this.title);
      this.modal.detail = Object.assign({}, this.detail);
      this.$bvModal.show(`modal-edit-${this.id}`);
    },
    handleModalCancel() {
      this.modal.activeStep = 1;
      this.modal.id = null;
      this.modal.title = { tr: '', en: '' };
      this.modal.detail = { tr: '', en: '' };
    },
    handleModalOk() {
      const postData = {
        id: this.id,
        title: JSON.stringify(this.modal.title),
        detail: JSON.stringify(this.modal.detail)
      }
      ApiService.post('provide/set.req.php', postData)
      .then(() => {
        this.$emit('edited', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('edited', 'danger', response.data.errorMessage);
      })
    },

    renameFile(file) {
      return Date.now() + '_' + file.name;
    },

    removeFile() {
      this.iconModal.provideIcon = null;
    },

    process (fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData();
      formData.append('image', file, file.name);

      const request = new XMLHttpRequest();
      request.open("POST", "https://api.zenofontours.com/admin/v1/provide/icon/upload.req.php");
      request.setRequestHeader('Authorization', JwtService.getToken());

      request.upload.onprogress = e => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      const self = this;
      request.onload = function() {
        if (request.status >= 200 && request.status < 300) {
          self.iconModal.provideIcon = file.name;
          load();
        } else {
          // Can call the error method if something is wrong, should exit after
          self.iconModal.provideIcon = '';
          error();
        }
      };

      request.send(formData);

      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();

          // Let FilePond know the request has been cancelled
          abort();
        }
      }; 
    },

    handleIconModalCancel() {
      this.iconModal.provideIcon = null;
    },

    handleIconModalOk() {
      const postData = {
        id: this.id,
        icon: this.iconModal.provideIcon
      }
      ApiService.post('provide/icon/set.req.php', postData)
      .then(() => {
        this.$emit('edited', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('edited', 'danger', response.data.errorMessage);
      })
    },

    removeModal() {
      const self = this;
      const body = this.$createElement(
        'span',
        { class: ['font-size-lg'] },
        `${this.title.tr} olanağını silmek istediğinize emin misiniz?`
      );
      this.$bvModal.msgBoxConfirm([body], {
        title: 'Silmek Üzeresiniz',
        size: 'md',
        buttonSize: 'lg',
        okVariant: 'dark font-weight-bolder py-4',
        okTitle: 'Evet, Sil!',
        cancelVariant: 'btn btn-hover-transparent-dark font-weight-bolder py-4',
        cancelTitle: 'Vazgeç',
        footerClass: 'd-flex flex-row align-items-center justify-content-between flex-fill p-2',
        hideHeaderClose: false,
        centered: true,
        noFade: true
      })
      .then(value => {
        if (value) { self.remove(); }
      })
      .catch(({ error }) => {
        alert(error);
      })
    },

    remove() {
      const self = this;
      this.isBlocking = true;
      const postData = {
        id: this.id
      }
      ApiService.post('provide/remove.req.php', postData)
      .then(() => {
        setTimeout(() => {
          this.$emit('edited', 'success', 'Başarılı! Olanak sistemden silindi.');  
        }, 1000);
      })
      .catch(({ response }) => {
        setTimeout(() => {
          self.isBlocking = false;  
          const body = this.$createElement(
            'span',
            { class: ['font-size-lg'] },
            response.data.errorMessage
          );
          self.$bvModal.msgBoxOk([body], {
            title: response.data.error,
            size: 'md',
            buttonSize: 'lg',
            okVariant: 'dark font-weight-bolder py-4',
            okTitle: 'Tamam',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
            noFade: true
          })
        }, 1000);
        
        //this.$emit('edited', 'danger', response.data.errorMessage);
      })
    }

  }
}
</script>

<style scoped>
.provide-title {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.provide-muted-title {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>