<template>
  <div>
    <ProvideList />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProvideList from "@/view/pages/provide/components/ProvideList";

export default {
  name: "ProvideListings",
  components: {
    ProvideList
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "ProvideListings" }]);
  },
};
</script>